import React from "react";
import { Container} from "@mui/material";
import styled from "@emotion/styled";
import { CustomGrid, DataImage, SectionHeading } from "../../../Styles/style";
import tokenSanta from "../../../../assets/images/tokenSanta.png";
import Paragraph from "../../Paragraph";
const ContinerWraper = styled(Container)`
  overflow: hidden;
  min-height: 100vh;
  width: 100%;
  padding: 0 !important;
  position: relative;
  z-index: 0;
  @media (max-width: 599px) {
    /* min-height: auto; */
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100vh);
  width: 100%;
  padding: 0 50px !important;
  display: grid;
  place-items: center;
  z-index: 1;
  @media (max-width: 599px) {
    min-height: auto;
    background-position: left;
    padding: 0 20px !important;
    /* min-height: auto; */
    background-image: none;
  }
`;
export const ColorGrid = styled(CustomGrid)`
  background: ${props => props.bg ? props.bg : "linear-gradient(to bottom, #6dbe45, #36982e)"};
  border: ${props => props.b ? props.b : "1px solid #034928"};
  box-shadow: ${props => props.bs ? props.bs :"5px 5px 0px #034928"};
  padding: 10px 15px;
  justify-content: space-between;
`;

const Tokenomics = () => {
 
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="tokenomics">
        <InnerContinerWraper>
          <CustomGrid container jc="space-between" g="40px 0">
            <CustomGrid container item xs={12} md={5} jc="start" g="20px">
              <CustomGrid item xs={12} jc="start" data-aos="zoom-in">
                <SectionHeading>Tokenomics</SectionHeading>
              </CustomGrid>
              <CustomGrid item xs={12} jc="start" data-aos="fade-left">
                <Paragraph>
                  With a steep starting tax to fend off bots, Santa evolves into a 
                  magical 0/0 tax haven. Join us in a tax-free sleigh ride where the 
                  joy of giving knows no bounds!
                </Paragraph>
              </CustomGrid>
              <ColorGrid data-aos="fade-up" data-aos-duration="1000" item xs={12} jc="start">
                <Paragraph fm="PoppinsSB" fs="18px" fs599="16px">Name</Paragraph>
                <Paragraph fm="PoppinsSB" fs="18px" fs599="16px">Santa Coin</Paragraph>
              </ColorGrid>
              <ColorGrid data-aos="fade-up" data-aos-duration="1200" item xs={12} jc="start">
                <Paragraph fm="PoppinsSB" fs="18px" fs599="16px">Symbol</Paragraph>
                <Paragraph fm="PoppinsSB" fs="18px" fs599="16px">$SANTA</Paragraph>
              </ColorGrid>
              <ColorGrid data-aos="fade-up" data-aos-duration="1400" item xs={12} jc="start">
                <Paragraph fm="PoppinsSB" fs="18px" fs599="16px">Supply</Paragraph>
                <Paragraph fm="PoppinsSB" fs="18px" fs599="16px">8,000,000,000</Paragraph>
              </ColorGrid>
              <ColorGrid data-aos="fade-up" data-aos-duration="1600" item xs={12} jc="start">
                <Paragraph fm="PoppinsSB" fs="18px" fs599="16px">Tax</Paragraph>
                <Paragraph fm="PoppinsSB" fs="18px" fs599="16px">0/0</Paragraph>
              </ColorGrid>
              <ColorGrid data-aos="fade-up" data-aos-duration="1800" item xs={12} jc="start">
                <Paragraph fm="PoppinsSB" fs="18px" fs599="16px">Liquidity</Paragraph>
                <Paragraph fm="PoppinsSB" fs="18px" fs599="16px">Burnt</Paragraph>
              </ColorGrid>

            </CustomGrid>
            <CustomGrid container item xs={12} md={6} ai="start" g="40px 0" data-aos="zoom-in">
              <DataImage mw="70%" src={tokenSanta} />
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default Tokenomics;
