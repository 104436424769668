import styled from "@emotion/styled";
import { Container, Grid, Link } from "@mui/material";
import React from "react";
import x from "../../../../assets/images/x.png";
import tg from "../../../../assets/images/tg.png";
import Paragraph from "../../Paragraph";
import { DataImage, CustomGrid } from "../../../Styles/style";
import { MenuLink } from "../../LockerMenu/styles";
import CustomButton from "../../CustomBtn";
const ContinerWraper = styled(Container)`
  position: relative;
  height: auto;
  width: 100%;
  padding:  0 !important;
`;
const INPUT = styled.input`
  background: #1d263b;
  width: 100%;
  color: #ffff;
  padding: 10px;
  border: none;
  box-shadow: none;
  :focus {
    box-shadow: none;
    outline: none;
  }
`;
const TEXTAREA = styled.textarea`
  background: #1d263b;
  width: 100%;
  min-height: 80px;
  color: #ffff;
  padding: 10px;
  border: none;
  box-shadow: none;
  :focus {
    box-shadow: none;
    outline: none;
  }
`;
const Footer = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl">
        <Container>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems:"start",
              rowGap: "20px",
              zIndex: "1",
              columnGap: "30px",
              padding: "50px 0 0px",
            }}
          >
            <CustomGrid container item xs={12} md={5} jc="start" g="20px">
              <CustomGrid item xs={10} jc="start"  data-aos="zoom-in">
                <Paragraph>Join Our Community</Paragraph>
              </CustomGrid>
              <CustomGrid item xs={10} jc="start"  data-aos="fade-up" data-aos-duration="1000">
                <Paragraph>
                Join our vibrant community of enthusiasts, united by the joy of 
                Santa Coin. From festive discussions to shared excitement, we 
                celebrate the magic of the season together!
                </Paragraph>
              </CustomGrid>
              <CustomGrid item xs={10} jc="start"  data-aos="fade-up" data-aos-duration="1000">
                <Paragraph>
                Email: info@santacoin.finance
                </Paragraph>
              </CustomGrid>
              <CustomGrid jc="start" item xs={12}  data-aos="fade-up" data-aos-duration="1200">
                <div style={{ display: "flex", gap: "10px" }}>
                  <MenuLink p="0" href="https://t.me/santacoin_eth" target="blank">
                    <DataImage mw="40px" src={tg} />
                  </MenuLink>
                  <MenuLink p="0" href="https://twitter.com/santacoin_eth" target="blank">
                    <DataImage mw="40px" src={x} />
                  </MenuLink>
                </div>
              </CustomGrid>
            </CustomGrid>
            <CustomGrid
              item
              xs={12}
              md={2}
              fd="column"
              ai="start"
              jc="space-evenly"
              p="0 0 20px"
              g="10px"
              data-aos-duration="1000"
            >
              <Paragraph p="0 0 10px 0px" ta="left" color="#fff"  data-aos="zoom-in">
                Links
              </Paragraph>
              <Link data-aos="fade-up" data-aos-duration="1000" sx={{ textDecoration: "none" }} href="#home" >
                <Paragraph fs="14px" fs599="12px" ta="left" color="#fff">
                  Home
                </Paragraph>
              </Link>
              <Link data-aos="fade-up" data-aos-duration="1400" sx={{ textDecoration: "none" }} href="#features">
                <Paragraph fs="14px" fs599="12px" ta="left" color="#fff">
                Features
                </Paragraph>
              </Link>
              <Link data-aos="fade-up" data-aos-duration="1200" sx={{ textDecoration: "none" }} href="#tokenomics">
                <Paragraph fs="14px" fs599="12px" ta="left" color="#fff">
                 Tokenomics
                </Paragraph>
              </Link>
              {/* <DataImage mw="100px" mw599="50px" src={image1} /> */}
              <Link data-aos="fade-up" data-aos-duration="1600" sx={{ textDecoration: "none" }} href="#howtobuy">
                <Paragraph fs="14px" fs599="12px" ta="left" color="#fff">
                How to Buy
                </Paragraph>
              </Link>
            </CustomGrid>
            <CustomGrid fd="column" ai="start" g="20px" p="0 0 50px" item xs={12} md={4}>
              <Paragraph ta="left" color="#fff"  data-aos="zoom-in">
                Contact us
              </Paragraph>
              <INPUT placeholder="Email" data-aos="fade-up" data-aos-duration="1000"/>
              <TEXTAREA placeholder="Message" data-aos="fade-up" data-aos-duration="1200"/>
              <CustomButton data-aos="fade-up" data-aos-duration="1400">Send</CustomButton>
            </CustomGrid>
            <CustomGrid sx={{ borderTop: "1px solid #FCD5A5" }} item xs={12}>
              <Paragraph p="20px 0px">© 2023 $SANTA Project All Right Reserved</Paragraph>
            </CustomGrid>
          </Grid>
        </Container>
      </ContinerWraper>
    </>
  );
};

export default Footer;
