import React, { useState } from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";
import { CustomGrid, SectionHeading } from "../../../Styles/style";
import Santa_howtobuy from "../../../../assets/images/Santa_howtobuy.mp4";
import Paragraph from "../../Paragraph";
import { ColorGrid } from "../S3-Tokenomics";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CustomButton from "../../CustomBtn";
import { MenuLink } from "../../LockerMenu/styles";
const ContinerWraper = styled(Container)`
  overflow: hidden;
  min-height: 100vh;
  width: 100%;
  padding: 0px 0 !important;
  position: relative;
  z-index: 0;
  @media (max-width: 599px) {
    /* min-height: auto; */
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100vh);
  width: 100%;
  padding: 0 50px !important;
  display: grid;
  place-items: center;
  z-index: 1;
  @media (max-width: 599px) {
    min-height: auto;
    background-position: left;
    padding: 0 20px !important;
    /* min-height: auto; */
    background-image: none;
  }
`;

const HowToBuy = () => {
  const [showToast, setShowToast] = useState(false);
  const addressToCopy = "0x783EeF269728911AEB3e87388444C259C91715D7";

  const handleCopy = () => {
    navigator.clipboard.writeText(addressToCopy);
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 4000);
  };

  return (
    <>
      <ContinerWraper maxWidth="xxl" id="howtobuy">
        <InnerContinerWraper>
          <CustomGrid container jc="space-between" g="40px 0">
            <CustomGrid
              container
              item
              xs={12}
              md={5}
              ai="start"
              g="40px 0"
              data-aos="zoom-in"
            >
              <video
                style={{ width: "100%" }}
                src={Santa_howtobuy}
                muted
                autoPlay
                loop
              />
            </CustomGrid>
            <CustomGrid container item xs={12} md={6} jc="start" g="20px">
              <CustomGrid item xs={12} sm={10} jc="start" data-aos="zoom-in">
                <Paragraph>
                  Buy Now and <span style={{ color: "#FCED3D" }}>Join Us</span>
                </Paragraph>
              </CustomGrid>
              <CustomGrid item xs={12} sm={10} jc="start" data-aos="zoom-in">
                <SectionHeading>How To Buy $SANTA</SectionHeading>
              </CustomGrid>
              <CustomGrid item xs={12} sm={10} jc="start" data-aos="fade-left">
                <Paragraph>
                  <span style={{ color: "#67BA43" }}>▶</span> Create a Ethereum
                  (ERC20) wallet (we recommend Metamask or Trust Wallet) and
                  send ETH to your ERC20 wallet address. <br />
                  <br />
                  <span style={{ color: "#67BA43" }}>▶</span> Now you can easily
                  swap your ETH for $SANTA with Slippage %. Please remember to
                  leave a small amount of ETH for network fees. <br />
                  <br />
                  <span style={{ color: "#67BA43" }}>▶</span> Congratulations,
                  you are now part of the $SANTA Family! <br />
                </Paragraph>
              </CustomGrid>
              <CustomGrid
                item
                xs={10}
                sm={12}
                fd599="column"
                ai="start"
                g="10px 0"
                jc="space-between"
                data-aos="fade-left"
              >
                <MenuLink p="0"
                  href="https://app.uniswap.org/#/swap?outputCurrency=0x783EeF269728911AEB3e87388444C259C91715D7&chain=ethereum"
                  target="bkank"
                >
                  <CustomButton>Buy Now</CustomButton>
                </MenuLink>
                <MenuLink p="0"
                  href="https://www.dextools.io/app/en/ether/pair-explorer/0x783EeF269728911AEB3e87388444C259C91715D7"
                  target="bkank"
                >
                  <CustomButton>View Chart</CustomButton>
                </MenuLink>
                <MenuLink p="0"
                  href="https://etherscan.io/token/0x783EeF269728911AEB3e87388444C259C91715D7"
                  target="bkank"
                >
                  <CustomButton>Etherscan</CustomButton>
                </MenuLink>
              </CustomGrid>
              <ColorGrid
                data-aos="fade-left"
                bg="linear-gradient(to bottom, #FCEE21, #FA920B)"
                b="1px solid #C6380C"
                bs="5px 5px 0px #600303"
                item
                xs={12}
              >
                {showToast ? (
                  <Paragraph
                    color="#600303"
                    fm="PoppinsSB"
                    fs="18px"
                    fs599="16px"
                  >
                    Contract Address Copied
                  </Paragraph>
                ) : (
                  <>
                    <Paragraph
                      color="#600303"
                      fm="PoppinsSB"
                      fs="18px"
                      fs599="16px"
                    >
                      CA
                    </Paragraph>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <Paragraph
                        color="#600303"
                        fm="PoppinsSB"
                        fs="16px"
                        fs599="14px"
                        sx={{ wordBreak: "break-all" }}
                      >
                        {addressToCopy}
                      </Paragraph>
                      <FileCopyIcon
                        onClick={handleCopy}
                        sx={{ cursor: "pointer", color: "#600303" }}
                      />
                    </div>
                  </>
                )}
              </ColorGrid>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default HowToBuy;
