import React from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";
import { AbsoluiteImage, CustomGrid } from "../../../Styles/style";
import backgroundImage from "../../../../assets/images/backgroundImage.png";
import heroSanta from "../../../../assets/images/heroSanta.png";
import tree from "../../../../assets/images/tree.png";
import house from "../../../../assets/images/house.png";
import heroHeading from "../../../../assets/images/heroHeading.png";
import LockerMenu from "../../LockerMenu";
const ContinerWraper = styled(Container)`
  overflow: hidden;
  min-height: 100dvh;
  width: 100%;
  background-image: url(${backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 !important;
  position: relative;
  @media (max-width: 599px) {
    /* min-height: auto; */
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: calc(100dvh - 70px);
  width: 100%;
  padding: 0 50px !important;
  z-index: 1;
  @media (max-width: 599px) {
    min-height: auto;
    background-position: left;
    padding: 0 20px !important;
    /* min-height: auto; */
    background-image: none;
  }
`;

const HeroSection = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="home">
        <LockerMenu />
        <InnerContinerWraper>
          <CustomGrid container>
            <CustomGrid item xs={12}>
              <AbsoluiteImage  t="0%" t599="10%" tran="translate(-50%,0%)" mw="40%" mw1099="70%" mw999="80%" mw599="100%" src={heroSanta}/>
            </CustomGrid>
            <CustomGrid item xs={12}>
              <AbsoluiteImage className="tree" src={tree} style={{height:"60%"}}/>
            </CustomGrid>
            <CustomGrid item xs={12}>
              <AbsoluiteImage t="58%" tran="translate(-50%,-58%)" mw="25%" mw1099="50%"  mw999="70%" src={heroHeading}/>
            </CustomGrid>
            <CustomGrid item xs={12}>
              <AbsoluiteImage className="house" src={house}/>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </ContinerWraper>
      
    </>
  );
};

export default HeroSection;
