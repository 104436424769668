import React from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";
import { CustomGrid, DataImage, SectionHeading } from "../../../Styles/style";
import feature1 from "../../../../assets/images/feature1.png";
import feature2 from "../../../../assets/images/feature2.png";
import feature3 from "../../../../assets/images/feature3.png";
import feature4 from "../../../../assets/images/feature4.png";
import Paragraph from "../../Paragraph";
const ContinerWraper = styled(Container)`
  overflow: hidden;
  /* min-height: 100vh; */
  width: 100%;
  padding: 100px 0 !important;
  position: relative;
  z-index: 0;
  @media (max-width: 599px) {
    /* min-height: auto; */
  }
`;

const InnerContinerWraper = styled(Container)`
  /* min-height: calc(100vh); */
  width: 100%;
  display: grid;
  place-items: center;
  padding: 20px 50px !important;
  z-index: 1;
  @media (max-width: 599px) {
    min-height: auto;
    background-position: left;
    padding: 20px !important;
    /* min-height: auto; */
    background-image: none;
  }
`;

const FeaturesSection = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="features">
        <InnerContinerWraper>
          <CustomGrid container jc="space-between" g="40px 0">
            <CustomGrid container item xs={12} md={5} jc="start" g="20px">
              <CustomGrid item xs={12} jc="start" data-aos="zoom-in">
                <Paragraph>
                  Main feature <span style={{ color: "#FCED3D" }}>points</span>
                </Paragraph>
              </CustomGrid>
              <CustomGrid item xs={12} jc="start" data-aos="zoom-in">
                <SectionHeading>
                  Community Driven <br /> & ETH Based Ecosystem
                </SectionHeading>
              </CustomGrid>
              <CustomGrid item xs={12} jc="start" data-aos="fade-left">
                <Paragraph>
                  Unleash the magic of the holiday season with SantaBot – your
                  festive companion in the digital realm! SantaBot, powered by
                  AI technology, brings the spirit of Santa Claus to life in
                  your Telegram group. This innovative bot is more than just
                  lines of code; it's here to spread joy, laughter, and a touch
                  of holiday enchantment.
                </Paragraph>
              </CustomGrid>
            </CustomGrid>
            <CustomGrid
              container
              item
              xs={12}
              md={6}
              ai="start"
              g="40px 0"
              jc="space-between"
            >
              <CustomGrid
                container
                item
                xs={12}
                sm={6}
                jc="start"
                fd="column"
                ai="start"
              >
                <DataImage mw="40px" src={feature1} data-aos="zoom-in" />
                <Paragraph data-aos="fade-left" fs="20px" fs599="18px">
                  Liquidity burned
                </Paragraph>
                <Paragraph data-aos="fade-left">Liquidity burned</Paragraph>
              </CustomGrid>
              <CustomGrid
                container
                item
                xs={12}
                sm={6}
                jc="start"
                fd="column"
                ai="start"
              >
                <DataImage mw="40px" src={feature2} data-aos="zoom-in" />
                <Paragraph data-aos="fade-left" fs="20px" fs599="18px">
                  Community Driven
                </Paragraph>
                <Paragraph data-aos="fade-left">
                  Fully decentralized and community driven
                </Paragraph>
              </CustomGrid>
              <CustomGrid
                container
                item
                xs={12}
                sm={6}
                jc="start"
                fd="column"
                ai="start"
              >
                <DataImage mw="40px" src={feature3} data-aos="zoom-in" />
                <Paragraph data-aos="fade-left" fs="20px" fs599="18px">
                  No Tax
                </Paragraph>
                <Paragraph data-aos="fade-left">0% Transaction fee</Paragraph>
              </CustomGrid>
              <CustomGrid
                container
                item
                xs={12}
                sm={6}
                jc="start"
                fd="column"
                ai="start"
              >
                <DataImage mw="40px" src={feature4} data-aos="zoom-in" />
                <Paragraph data-aos="fade-left" fs="20px" fs599="18px">
                  Security
                </Paragraph>
                <Paragraph data-aos="fade-left">
                  Dropped admin privilleges
                </Paragraph>
              </CustomGrid>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default FeaturesSection;
