import React, { useEffect } from "react";
import { PageWrapper } from "../../Styles/style";
import AOS from "aos";
import "aos/dist/aos.css";
import HeroSection from "../../Components/Sections/S1-Hero";
import FeaturesSection from "../../Components/Sections/S2-Features";
import Tokenomics from "../../Components/Sections/S3-Tokenomics";
import HowToBuy from "../../Components/Sections/S4-How To Buy";
import Footer from "../../Components/Sections/S6-Footer";
import snow from "../../../assets/images/Snow.mp4"
import styled from "@emotion/styled";
const VideoBG = styled.video`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0%;
  left: 0%;
  object-fit: cover;
  mix-blend-mode: plus-lighter;

`
const MainPage = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <PageWrapper>
      <HeroSection />
      <VideoBG src={snow} autoPlay loop muted />
      <FeaturesSection />
      <Tokenomics />
      <HowToBuy />
      <Footer /> 
    </PageWrapper>
  );
};

export default MainPage;
