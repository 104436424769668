import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import React from 'react'

const Heading = styled(Typography)`
    padding: ${props=> props.p ? props.p : "0px"};
    margin: ${props=> props.m ? props.m : "0"};
    text-align: ${props=> props.ta ? props.ta : "left"};
    font-size: ${props=> props.fs ? props.fs : "16px"};
    font-family: ${props=> props.fm ? props.fm : "PoppinsR"};
    position: relative;
    z-index: 9;
    @media(max-width:599px){
      padding: ${props=> props.p599 };
      font-size: ${props=> props.fs599 ? props.fs599 : "14px"};
      text-align: ${props=> props.ta599 };
  }
`

const Paragraph = (props) => {
    const {children} = props
  return (
    <Heading color="#fff" {...props}>
        {children}
    </Heading>
  )
}

export default Paragraph